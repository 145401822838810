<style scoped>
</style>

<template>
    <v-avatar :size="size" :color="avatar ? 'transparent' : 'primary'" :style="getStyle()" @click="$emit('click')">
        <img v-if="avatar && !error" alt="Avatar" :src="getImage()" @error="imageError">
        <div v-else>{{ getName() }}</div>
    </v-avatar>
</template>

<script>
//import events from "../../app/app.events";

export default {
    props: {
        size: {},
        avatar: {},
        name: {}
    },
    data: function () {
        return {
            error: false
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    watch: {
        avatar() {
            this.error = false;
        }
    },
    methods: {
        imageError() {
            this.error = true;
        },
        getStyle() {
            if (this.size >= 50) {
                return { "font-size": "40px" };
            }
            return {};
        },
        getImage() {
            var imageSize = "medium";
            if (this.size < 50) {
                imageSize = "thumbnail";
            }
            return this.$asset.getUrl(this.avatar, imageSize);
        },
        getName() {
            if (!this.name) {
                return "?";
            }

            var names = this.name.split(" ");

            var initials = names[0].substring(0, 1);
            if (names.length > 1) {
                initials += names[1].substring(0, 1);
            }
            return initials;
        }
    },
    components: {
    }
}
</script>