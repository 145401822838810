import Vue from "vue";
import Vuex from "vuex";
import VueRx from "vue-rx";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { Ripple } from "vuetify/lib/directives";

import themes from "./styles/themes";

Vue.use(Vuex);
Vue.use(Vuetify, {
    directives: {
        Ripple
    }
});

Vue.use(VueRx);

export var vuetify = new Vuetify({
    theme: {
        themes: themes,
        options: {
            customProperties: true
        },                
    }
});
