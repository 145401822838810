"use strict";

//import LZString from "lz-string";

// START: Base64 encoding tools
var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

var lookup = new Uint8Array(256);
for (var i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i;
}
// END: Base64 encoding tools

export default {
    jwtDecode(token) {
        if (!token) {
            return null;
        }
        const [/*header*/, payload] = token.split(".");
        return JSON.parse(window.atob(payload));
    },
    deepCopy(object) {
        return JSON.parse(JSON.stringify(object));
    },
    async fileDataSerialize(blob) {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
        
            try {
                reader.readAsDataURL(blob); 
            }
            catch {
                reject("blob read failed");
            }
            reader.onloadend = function() {
                var base64 = reader.result;
                base64 = base64.split(',')[1];
                resolve(base64);
            }
        });
    },
    fileDataDeserialize(encodedString) {
        return this.base64.decode(encodedString);
    },
    base64: {
        encode(arraybuffer) {
            var bytes = new Uint8Array(arraybuffer),
            i, len = bytes.length, base64 = "";
        
            for (i = 0; i < len; i+=3) {
                base64 += chars[bytes[i] >> 2];
                base64 += chars[((bytes[i] & 3) << 4) | (bytes[i + 1] >> 4)];
                base64 += chars[((bytes[i + 1] & 15) << 2) | (bytes[i + 2] >> 6)];
                base64 += chars[bytes[i + 2] & 63];
            }
        
            if ((len % 3) === 2) {
                base64 = base64.substring(0, base64.length - 1) + "=";
            } else if (len % 3 === 1) {
                base64 = base64.substring(0, base64.length - 2) + "==";
            }
        
            return base64;
        },
        decode(base64) {
            var bufferLength = base64.length * 0.75,
            len = base64.length, i, p = 0,
            encoded1, encoded2, encoded3, encoded4;
        
            if (base64[base64.length - 1] === "=") {
                bufferLength--;
                if (base64[base64.length - 2] === "=") {
                bufferLength--;
                }
            }
        
            var arraybuffer = new ArrayBuffer(bufferLength),
            bytes = new Uint8Array(arraybuffer);
        
            for (i = 0; i < len; i+=4) {
                encoded1 = lookup[base64.charCodeAt(i)];
                encoded2 = lookup[base64.charCodeAt(i+1)];
                encoded3 = lookup[base64.charCodeAt(i+2)];
                encoded4 = lookup[base64.charCodeAt(i+3)];
        
                bytes[p++] = (encoded1 << 2) | (encoded2 >> 4);
                bytes[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
                bytes[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
            }
        
            return arraybuffer;
        }
    }
}