export default {
    getParentPath(instance) {
        try {
            return instance.$parent.$route.meta.basePath
        }
        catch (e) {
            return "";
        }
    },
    isNameRegistered(router, routeName) {
        const registered = router.resolve({ name: routeName });
        if (registered && registered.href !== "#/" && registered.href !== "/") {
            return true;
        }
        return false;
    }
}