<style scoped>
</style>

<template>
    <router-view></router-view>
</template>

<script>
export default { 
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>