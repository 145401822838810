<style scoped>
</style>

<template>
    <v-snackbar v-model="show" :timeout="timeout" bottom :style="{ bottom: bottom, marginBottom: '10px' }">
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="show = false">Close</v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import config from "../app.config";

import events from "../app.events";

export default { 
    data: function () {
        return {
            show: false,
            timeout: 3000,
            message: "",
            bottom: config.footer.height + "px"
        }
    },
    mounted: function () {
    },
    created: function () {
        this.$bus.handle(events.APP_SNACKBAR_MESSAGE, this.snackbarMessage.bind(this));
    },
    computed: {
    },
    methods: {
        snackbarMessage(message) {
            this.message = message;
            this.show = true;
        }
    },
    components: {
    }
}
</script>