export default {
    app: {
        name: "Template"
    },
    header: {
        height: 60
    },
    footer: {
        height: 60
    }
}