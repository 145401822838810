import util from "@/util";
import Vue from "vue";

Vue.mixin({
    created() {
        this.$asset = {
            getUrl(image, size) {
                return util.image.getUrl(image, size);
            }
        }
    },
});