<style scoped>
    .record-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<template>
    <v-container class="record-container fill-height">
        <div>
            Coming Soon
        </div>
    </v-container> 
</template>

<script>
export default { 
    data: function () {
        return {
        }
    },
    mounted: function () {
    },
    created: function () {
    },
    computed: {
    },
    methods: {
    },
    components: {
    }
}
</script>