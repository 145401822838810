import util from "@/util";
import config from "@/config";
import Vue from "vue";

Vue.mixin({
    created() {
        var that = this;
        this.$api = {
            async get(path, user, throwError) {
                return util.http.get(config.api.uri, that, path, user, throwError);
            },
            async delete(path, user, throwError) {
                return util.http.delete(config.api.uri, that, path, user, throwError);
            },
            async post(path, payload, user, throwError, isForm) {
                return util.http.post(config.api.uri, that, path, payload, user, throwError, isForm);
            },
        }
    },
});