import Vue from "vue";

class BusClient {
    constructor(bus) {
        this._bus = bus;
    }
    handlers = {}
    handle(event, handler) {
        this.handlers[event] = handler;
        this._bus.$on(event, this.handlers[event]);
    }
    emit(event, payload) {
        this._bus.$emit(event, payload);
    }
    destroy() {
        for (const [key, value] of Object.entries(this.handlers)) {
            this._bus.$off(key, value);
        }
    }
}

Vue.mixin({
    created() {
        this.$bus = new BusClient(this.$root);
    },
    beforeDestroy() {
        this.$bus.destroy();
    }
});

export default BusClient
