import http from "./http.utils";
import router from "./router.utils";
import identifier from "./identifier.utils";
import image from "./image.utils";
import tools from "./tools.utils";

export default {
    http,
    router,
    identifier,
    image,
    tools
};